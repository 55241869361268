:root {
    --gap: 20px;
    --blue: #0d6efd;
    --red: #dc3545;
    --lblue: #EBF7FF;
    --transition: all 0.45s ease-out;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.4;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

h1,
h2,
h3 {
    font-weight: 500;
}

h1,
.h1 {
    font-size: 2.4em;
    margin-bottom: .5em;
}

h1.go_back {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: .5em;
}

.h1_input {
    display: block;
    width: 100%;
    border: 0 none;
    padding: 0;
    font-weight: inherit;
}

.cloacks_h1 {
    display: flex;
    justify-content: space-between;
}

h2 {
    font-size: 2em;
    margin-bottom: .5em;
    font-weight: 400;
}

h3 {
    font-size: 1.5em;
    margin-bottom: .5em;
    font-weight: 400;
}

ul:not([class]) {
    list-style: none;
    padding: 0 0 .5em 0;
}

ul:not([class]) li {
    position: relative;
    padding: 0 0 .5em 1.5em;
}

ul:not([class]) li::before {
    content: '';
    position: absolute;
    top: .65em;
    left: 0;
    width: 1em;
    height: 1px;
    background: var(--blue);
}

p {
    margin-bottom: 1em;
}

a {
    color: var(--blue);
    text-decoration: underline;
}

a:hover {
    color: var(--red);
}

a svg,
button svg {
    pointer-events: none;
}

.hidden {
    visibility: hidden;
}

button,
input,
textarea {
    font-size: inherit;
    font-family: inherit;
}

button {
    cursor: pointer;
    background: transparent;
    border: 0 none;
}

img {
    vertical-align: top;
}

strike {
    margin-left: .75em;
    position: relative;
    color: inherit;
}

strike::after {
    content: '';
    position: absolute;
    top: 42%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--red);
    pointer-events: none;
}

.form-control {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control_wrapper {
    position: relative;
}

.form-control_wrapper .form-control {
    outline: 0 none;
    padding: .75em;
    transition: none;
}

.form-control_wrapper .form-control select {
    height: 100%;
}

.form-control_wrapper .form-control:focus {
    border-color: var(--blue);
}

.form-control-label {
    position: absolute;
    top: calc(1px + 0.75rem);
    left: calc(1px + 0.75rem - 5px);
    padding: 0 5px;
    font-size: 1rem;
    line-height: 1.5;
    white-space: nowrap;
    color: #495057;
    pointer-events: none;
}

.form-control_wrapper .form-control:focus+.form-control-label,
.form-control_wrapper .form-control:not(:placeholder-shown)+.form-control-label {
    top: -9px;
    font-size: 12px;
    background: #FFF;
}

.form-control_wrapper .form-control:focus+.form-control-label {
    color: var(--blue);
}

.form-control-centered {
    text-align: center;
    padding: 0 .125rem 0 .125em;
}

div.form-control-centered {
    justify-content: center;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-lg {
    font-size: 1.5em;
}
.btn svg {
    margin-right: .5em;
}

.form-control_wrapper_help {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: help;
}

.form-control_wrapper_help:hover {
    color: var(--red);
}

.form-control_wrapper_help_popup {
    position: absolute;
    top: 50px;
    right: 0;
    padding: var(--gap);
    background: var(--red);
    color: #FFF;
    z-index: 50;
    transition: var(--transition);
    border-radius: 5px;
    transform: translateY(var(--gap));
    opacity: 0;
    visibility: hidden;
}

.form-control_wrapper_help:hover+.form-control_wrapper_help_popup {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.form-control_wrapper_text {
    font-size: 12px;
    margin: 1em 0;
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-info {
    color: #fff;
    background-color: green;
    border-color: green;
}

.btn-info:hover {
    color: #fff;
    opacity: .95;
}

.btn-outline {
    border-color: #ccc;
    color: #efefef;
}

.btn-outline:hover {
    border-color: #fff;
    color: #fff;
}

.btn-group {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.btn-group>* {
    border-radius: 0;
}

.btn-group>*:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
}

.btn-group>*:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
}

.label {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 10px;
    align-items: center;
    width: 100%;
    background: #efefef;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
}

.label svg {
    font-size: 1.75em;
}

.settings_columns_checkboxes.smart_signals .label svg path {
    fill: #A2AAAD;
}

.list {
    list-style: none;
    padding: 0 0 .5em 0;
}

.list li {
    position: relative;
    padding: 0 0 .5em 1.5em;
}

.list li::before {
    content: '';
    position: absolute;
    top: .65em;
    left: 0;
    height: 1px;
    width: 1em;
    background: var(--red);
}

.green {
    color: green;
}

.red {
    color: var(--red);
}

.grey {
    color: grey;
}

.stat_columns {
    display: flex;
    gap: var(--gap);
    background: #FFF;
    padding: 5px 1.2em;
    border-radius: 30px;
}

.auth_page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_page_form {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    width: 320px;
    padding: var(--gap);
    background: #efefef;
    border-radius: 10px;
}

.auth_page_form_error {
    background: var(--red);
    color: #FFF;
    padding: 7px;
    text-align: center;
    border-radius: 5px;
}

.auth_page_langs {
    position: absolute;
    top: var(--gap);
    right: var(--gap);
}

.auth_page_langs .btn {
    border-radius: 0;
}

.auth_page_langs .btn:first-child {
    border-radius: .375em 0 0 .375em;
}

.auth_page_langs .btn:last-child {
    border-radius: 0 .375em .375em 0;
}

.total_pages {
    position: relative;
}

.total_pages::before {
    content: '/';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -3px;
    display: flex;
    align-items: center;
    font-size: .75em;
}

.stat_header {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr auto 2fr 2fr;
    gap: var(--gap);
    margin-bottom: var(--gap);
}

.stat_header_page {
    display: grid;
    grid-template-columns: 1fr 42px 42px 1fr;
}

.stat_header_page .form-control,
.stat_header_page .btn {
    border-radius: 0;
}

.stat_header_page .btn:first-child {
    border-radius: .375rem 0 0 .375em;
}

.stat_header_page .btn:last-child {
    border-radius: 0 .375rem .375em 0;
}

.stat_header_page .form-control {
    border-left: 0 none;
    border-right: 0 none;
}

.stat_header_dates {
    display: grid;
    grid-template-columns: 150px 150px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: var(--gap);
}

.table thead {
    background: #efefef;
    border-bottom: 4px solid #FFF;
}

.table tr:nth-child(2n) {
    border-bottom: 4px solid #FFF;
}

.table tr:hover {
    background: var(--lblue);
}

.table tr.error {
    background: #efefef;
}

.table tr.passed {
    background: #D4FFD4;
}

.table tr.blocked {
    background: #FFEDF0;
}

.table td {
    padding: 8px 15px;
    white-space: nowrap;
}

.table td.wrap {
    white-space: unset;
    word-break: break-all;
}

.table th {
    padding: 12px 15px;
    text-align: left;
}

.table_status {
    display: block;
    width: 1em;
    height: 1em;
    background: #efefef;
    border-radius: 50%;
}

.table_status_1 {
    background: green;
}

.table_status_0 {
    background: var(--red);
}

.table_icon {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: .5em;
}

.table_devices {
    display: flex;
    align-items: center;
    gap: .5em;
}

.table_domain_name {
    display: block;
    font-size: .75em;
    margin-top: 2px;
    opacity: .8;
}

.table.three th:nth-child(6n+2),
.table.three td:nth-child(6n+2),
.table.three td:nth-child(6n+3),
.table.three td:nth-child(6n+4) {
    background-color: #efefef;
}

.windows {
    color: #00A4EF;
}

.macos,
.mac {
    color: #A2AAAD;
}

.android {
    color: #3DDC84;
}

.linux {
    color: #000;
}

.chromeos {
    color: #0d6efd;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #efefef;
    padding: var(--gap);
}

.header_menu {
    list-style: none;
    display: flex;
    align-items: center;
    gap: var(--gap);
}

.header_menu_link svg {
    margin-right: 10px;
}

.header_menu_link.header_menu_link_logout svg {
    margin-right: 0;
}

.span_new {
    position: relative;
    display: inline-block;
    font-size: 10px;
    line-height: 1.2em;
    font-weight: bold;
    border-radius: 2px;
    color: #FFF;
    background: var(--red);
    padding: 0 3px;
    top: -1px;
}

.logout {
    display: none;
}

.header_menu_summ {
    font-weight: bold;
    color: green;
}

.header a,
.under_header a,
.header button,
.under_header button {
    white-space: nowrap;
    color: #000;
    text-decoration: none;
}

.header a:hover,
.under_header a:hover,
.header button:hover,
.under_header button:hover {
    color: var(--red);
}

.logo {
    display: flex;
    align-items: center;
    gap: .25em;
    font-size: 1.5em;
    text-decoration: none;
    font-weight: 700;
}

.logo svg {
    width: auto;
    height: 1.5em;
}

.logo:hover svg path {
    fill: var(--red)
}

.logo_emoji {
    font-size: 1.5em;
}

.under_header {
    display: none;
}

.under_header.active {
    display: block;
    background: #efefef;
    padding: var(--gap);
}

.under_header .header_menu {
    flex-direction: column;
}

.content {
    padding: var(--gap);
}

.settings_columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap);
    margin-bottom: var(--gap);
}

.settings_columns_three {
    grid-template-columns: 1fr 1fr 1fr;
}

.settings_columns_checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--gap);
    margin-bottom: calc(2*var(--gap));
}

.settings_columns_checkboxes_text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.settings_columns_checkboxes>div:nth-child(1),
.settings_columns_checkboxes>div:nth-child(2),
.settings_columns_checkboxes>div:nth-child(3),
.settings_columns_checkboxes>div:nth-child(4) {
    /* margin: var(--gap) 0; */
    /* margin: 0 0 var(--gap) 0; */
    margin: 0;
}

/*
.settings_columns_checkboxes>div:nth-child(1) .label,
.settings_columns_checkboxes>div:nth-child(2) .label,
.settings_columns_checkboxes>div:nth-child(3) .label,
.settings_columns_checkboxes>div:nth-child(4) .label,
.settings_columns_checkboxes>div:nth-child(5) .label,
.settings_columns_checkboxes>div:nth-child(6) .label
{
    background: #ebf7ff;
}
*/
.settings_code_tabs {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 0 var(--gap);
}

.settings_code_tabs_button {
    color: #000;
    border-radius: 5px 5px 0 0;
    padding: 4px 1em 0 1em;
    line-height: 36px;
    transition: var(--transition);
    background: #efefef;
}

.settings_code_tabs_button:hover,
.settings_code_tabs_button.current {
    background: #000;
    color: #FFF;
}

.settings_code {
    padding: var(--gap);
    color: #FFF;
    background: #000;
    font-family: monospace;
    border-radius: 10px;
    margin-bottom: var(--gap);
}

.settings_code pre {
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    margin-bottom: 1em;
}

.table_nowrap {
    display: block;
    max-width: 240px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.settings_code_non_selectable {
    user-select: none;
    pointer-events: none;
    opacity: .25;
}

[data-help] select {
    height: 100%;
}

[data-help] {
    position: relative;
}

[data-help]::after {
    content: attr(data-help);
    position: absolute;
    top: -15px;
    left: 0;
    white-space: nowrap;
    font-size: 13px;
    line-height: 1em;
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: 0;
    transform: translateY(50%);
}

[data-help]:hover::after {
    opacity: 1;
    transform: translateY(0);
}

.oscountry {
    display: flex;
    gap: 1em;
    align-items: center;
}

.add_cloack_wrapper {
    position: fixed;
    inset: 0;
    z-index: 10;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_cloack_wrapper.active {
    visibility: visible;
}

.add_cloack {
    position: relative;
    padding: var(--gap);
    border-radius: 10px;
    background: #FFF;
    z-index: 2;
    transition: var(--transition);
    opacity: 0;
    transform: translateY(50px);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    align-items: center;
    min-width: 420px;
}

.add_cloack_wrapper.active .add_cloack {
    transform: translateY(0);
    opacity: 1;
}

.add_cloack_overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, .85);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}

.add_cloack_wrapper.active .add_cloack_overlay {
    opacity: 1;
}

.add_cloack h2 {
    margin: 0;
}

.burger_menu {
    display: none;
}
.burger_menu svg {
    margin: 0;
}

.preloader_content_example {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--gap);
    margin-bottom: var(--gap);
}

.preloader_content {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
}

.preloader_example_code {
    font-family: monospace;
    border-radius: 10px;
    background: #000;
    color: #CCC;
    padding: 1em;
}

.preloader_color_transperency {
    display: flex;
    gap: var(--gap);
}

.form-control_wrapper_color::before {
    content: '#';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: #ccc;
    pointer-events: none;
}

.form-control_wrapper_color .form-control {
    padding-left: 40px;
    text-transform: uppercase;
}

.form-control_wrapper_transparency::before {
    content: '%';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: #ccc;
    pointer-events: none;
}

.form-control_wrapper_transparency .form-control {
    min-width: 200px;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
}

.form-control_wrapper_transparency .form-control::-webkit-inner-spin-button,
.form-control_wrapper_transparency .form-control::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.backround_preview {
    border: 1px solid #000;
    width: 50px;
}

/* loader */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
    z-index: 1000;
    transition: var(--transition);
    transform: translateY(-10px);
}

.loader.active {
    transform: translateY(0);
}

.img_wide_mobile {
    width: 100%;
    height: auto;
    max-width: 320px;
}

.img_wide_desktop {
    width: 100%;
    height: auto;
    max-width: 1000px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* loader */

.show900 {
    display: none;
}

.account_expires {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: var(--gap);
    margin-bottom: var(--gap);
}

.account_expires_content {
    padding: 1em;
    background: #efefef;
    border-radius: 10px;
    font-weight: 500;
    min-width: 200px;
}

.account_expires_content_when {
    display: block;
    font-size: 2.5em;
}

.help_table {
    min-width: 33%;
    border-collapse: collapse;
    margin-bottom: var(--gap);
}

.help_table thead {
    text-align: left;
}

.help_table td,
.help_table th {
    border: 1px solid #CCC;
    padding: 6px 15px;
}

.settings_split {
    position: relative;
    border: 1px solid #CCC;
    border-radius: 10px;
    padding: calc(1.5*var(--gap)) var(--gap);
    margin-bottom: calc(2*var(--gap));
}

.settings_split_select {
    position: absolute;
    top: -17px;
    right: 25px;
    width: 320px;
}

.settings_split_label {
    position: absolute;
    top: -10px;
    padding: 0 10px;
    left: calc(var(--gap) - 10px);
    background: #FFF;
    color: #495057;
}

.settings_split_checkbox {
    margin-right: .5em;
}

.settings_split_columns {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.settings_split_grid {
    display: grid;
    grid-template-columns: 1fr auto 3fr auto 3fr auto;
    gap: var(--gap);
}

.settings_split_grid_span {
    line-height: 100%;
    font-weight: 700;
    font-size: 1.2em;
    display: flex;
    align-items: center;
}

.settings_split_grid_unit {
    display: flex;
    align-items: center;
}

.settings_getvars_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    gap: var(--gap);
    padding: 5px;
}

.settings_getvars_grid_values {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: var(--gap);
    padding: 5px;
}

.settings_getvars_grid_values:hover {
    background: #ebf7ff;
}

.footer {
    background: #efefef;
    padding: var(--gap);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--gap);
    gap: var(--gap);
}

.langs {
    display: flex;
    font-size: .75em;
}

.langs .btn {
    border-radius: 0;
}

.langs .btn:first-child {
    border-radius: .375em 0 0 .375em;
}

.langs .btn:last-child {
    border-radius: 0 .375em .375em 0;
}

.stat_navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--gap);
}

.date_date_submit {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--gap);
}

.table_horizontal_wrapper {
    overflow-x: auto;
}

.top_stripe {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--gap);
    line-height: 50px;
    background: #555;
    color: #FFF;
    font-weight: bold;
}

.top_stripe a {
    color: #FFF;
}

.top_stripe.warning {
    background: orangered;
}

.top_stripe.danger {
    background: var(--red);
}

.top_stripe.info {
    background: green;
}

.top_stripe_close {
    width: 27px;
    height: 27px;
    position: relative;
    transform: rotate(45deg);
}

.top_stripe_close:hover {
    transform: rotate(405deg);
    transition: var(--transition);
}

.top_stripe_close::before,
.top_stripe_close::after {
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    width: 3px;
    bottom: 0;
    background: #FFF;
}

.top_stripe_close::after {
    top: 12px;
    left: 0;
    right: 0;
    width: auto;
    height: 3px;
}

.refresh_stat {
    position: fixed;
    right: var(--gap);
    bottom: var(--gap);
    z-index: 12345;
}
.refresh_stat svg {
    margin: 0;
}

.timezone {
    width: 33%;
    margin-bottom: var(--gap);
}

.grid-container {
    display: grid;
    gap: 5px;
    width: 100%;
    /* Растягиваем контейнер на всю ширину */
    max-width: 100%;
    /* Убираем возможные ограничения */
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    gap: 10px;
}

.grid-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    height: 50px;
    overflow: hidden;
}
.grid-item::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: content-placeholder-animation 1s linear infinite;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}
@keyframes content-placeholder-animation {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(300%);
    }
}

.blacklist_columns {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--gap);
}
.blacklist_columns_textarea {
    height: 600px;
}
.blacklist_columns_stat {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    white-space: nowrap;
}
.blacklist_columns_stat_ip_big {
    font-size: 2em;
}
.blacklist_columns_stat_whitelist {
    list-style: none;
    padding: .5em 0;
}
.blacklist_columns_stat_whitelist li {
    position: relative;
    padding: 0 0 .5em 1.5em;
}
.blacklist_columns_stat_whitelist li::before {
    content: '';
    position: absolute;
    top: .65em;
    left: 0;
    width: 1em;
    height: 1px;
    background: var(--blue);
}

@media (max-width: 1180px) {
    .hide1000 {
        display: none;
    }

    .settings_columns_checkboxes {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .settings_columns_checkboxes>div:nth-child(1),
    .settings_columns_checkboxes>div:nth-child(2),
    .settings_columns_checkboxes>div:nth-child(3),
    .settings_columns_checkboxes>div:nth-child(4) {
        margin: 0;
    }

    .stat_header,
    .stat_header_dates {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1000px) {
    .timezone {
        width: 50%;
    }
}

@media (max-width: 920px) {
    .burger_menu {
        display: block;
    }

    .header .header_menu {
        display: none;
    }

    .header_menu_link.header_menu_link_logout svg {
        margin-right: 10px;
    }

    .logout {
        display: inline;
    }
    .settings_split_label, .settings_split_select {
        position: static;
        padding: 0;
        display: block;
        margin-bottom: var(--gap);
    }
    .settings_getvars_grid {
        padding: 0;
    }
    .preloader_content_example {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 900px) {

    .table th,
    .table td {
        display: block;
    }

    .table thead {
        display: none;
    }

    .show900 {
        display: inline;
    }
}

@media (max-width: 850px) {
    .logo_text {
        display: none;
    }

    .account_expires {
        grid-template-columns: 1fr;
    }

    .stat_navigation {
        flex-direction: column;
        gap: var(--gap);
    }
}

@media (max-width: 750px) {
    .settings_columns_checkboxes {
        grid-template-columns: 1fr 1fr;
    }

    .settings_columns_three {
        grid-template-columns: 1fr;
    }

    .timezone {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .footer {
        flex-direction: column;
        gap: 1em;
        align-items: center;
    }
}

@media (max-width: 680px) {
    .settings_split_grid {
        grid-template-columns: 1fr;
    }
    .settings_split_grid_span {
        display: none;
    }
    .blacklist_columns {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 640px) {
    .variant {
        display: none;
    }
}

@media (max-width: 550px) {

    .settings_columns,
    .settings_columns_checkboxes,
    .stat_header {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .add_cloack {
        width: calc(100% - 2*var(--gap));
        min-width: unset;
    }
    .settings_getvars_grid_values, .settings_getvars_grid {
        grid-template-columns: 1fr;
    }
    .preloader_color_transperency {
        flex-direction: column;
    }
    .backround_preview {
        height: 50px;
    }
}

@media (max-width: 450px) {
    .clicks_dates {
        flex-direction: column;
    }
}

@media (max-width: 380px) {
    .logo {
        display: none;
    }
}